import { Slider, Tooltip, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDebouncedFunc } from "../../../../hooks/common.hooks";
import { primaryColor, secondaryColor } from "../../../../scss/colors.scss";
import { formatNumberWithDollar } from "../../../../utils/numberFormater";

const marksBuilder = (min, max) => {
  return [
    {
      value: min,
      label: `${formatNumberWithDollar(min)}`,
    },
    {
      value: max,
      label: `${formatNumberWithDollar(max)}`,
    },
  ];
};

const PrettoSlider = withStyles({
  root: {
    color: `${secondaryColor}`,
    height: 8,
    marginBottom: "1rem",
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: "#fff",
    border: `2px solid ${primaryColor}`,
    marginTop: -7,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% - 4px)",
  },
  track: {
    height: 2,
    borderRadius: 4,
  },
  rail: {
    height: 2,
    borderRadius: 4,
  },
})(Slider);

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={formatNumberWithDollar(value)}
    >
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};
function valuetext(value) {
  return `${value}`;
}
const SliderItem = ({
  className = "",
  title = "",
  min = 0,
  value = 0,
  max = 0,
  handleSliderValue = () => {},
  sliderStep = 100,
  sliderType = "",
}) => {
  const [marks, setMarks] = useState([]);
  const [maxValue, setMaxValue] = useState(max);
  const [sliderValue, setSliderValue] = useState(() => value);
  const debouncedHandleSliderValue = useDebouncedFunc(handleSliderValue, 700);

  useEffect(() => {
    setMaxValue(max);
  }, [max]);

  useEffect(() => {
    setMarks(marksBuilder(min, maxValue));
  }, [min, maxValue]);

  useEffect(() => {
    setSliderValue(value);
  }, [value]);

  const handleChange = (event, newValue) => {
    setSliderValue(newValue);
    debouncedHandleSliderValue({ [sliderType]: newValue });
  };

  return (
    <div className="scenario-slider-item">
      <div className="bc-title font-weight-bold" id={title}>
        {title}
      </div>
      <PrettoSlider
        className={className}
        ValueLabelComponent={ValueLabelComponent}
        min={min}
        max={maxValue}
        value={sliderValue}
        onChange={handleChange}
        defaultValue={sliderValue}
        getAriaValueText={valuetext}
        aria-labelledby={title}
        step={sliderStep}
        marks={marks}
        valueLabelDisplay="auto"
      />
    </div>
  );
};

SliderItem.propTypes = {
  title: PropTypes.string,
  min: PropTypes.number,
  handleSliderValue: PropTypes.func,
};

export default SliderItem;
